import React from "react";
import { BrowserRouter, Routes, Route,} from "react-router-dom";
import "./App.css";
import { BackTop } from "antd";
import { Footer,} from "./Components";
import { Claim, Community, Home, Nft, NoPage } from "./Pages";

function App() {
  return (
    <div className="App bg-zinc-950">

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/claim" element={<Claim />} />
          <Route path="/claim/nft" element={<Nft />} />
          <Route path="/claim/community" element={<Community />} />
          <Route path="/*" element={<NoPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>

      <BackTop />
    </div>
  );
}

export default App;
