import React, { useState } from "react";
import {
  a1,
  a10,
  a2,
  a3,
  a4,
  a5,
  a6,
  a7,
  a8,
  a9,
  bam2,
  tokenomics10,
  tokenomics15,
  tokenomics30,
  tokenomics40,
  tokenomics5,
  herobg,
} from "../Assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { MotionAnimate } from "react-motion-animate";
import { Navbar } from "../Components";
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect } from "react";
import axios from 'axios';
import { ABI } from './utils';
import { ConnectWallet, Web3Button, useContractRead, useContract, useAddress } from "@thirdweb-dev/react";

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const address = useAddress();
  const tokenContract = "0x427968d7C1D98d12ef88EE09a99d39C9fb058c71";
  const { contract } = useContract(tokenContract, ABI);
  const { data: claimed, isLoading: fetchingClaimed, refetch: refetchClaimed } = useContractRead(contract, "communityClaimed", [address || tokenContract]);

  const [loading, setLoading] = useState(true);
  const [proof, setProof] = useState(null);

  async function getProof(address) {
    try {
      setLoading(true);
      const response = await axios.get(`https://alpha-api-production.up.railway.app/claim/${address}`);
      setProof(response.data);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching eligibility:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (address) {
      getProof(address);
      refetchClaimed();
    }
  }, [address]);

  return (
    <div>
      {/* Hero */}
      <MotionAnimate
        delay={0.4}
        speed={2}
        ease={[0.75, 0.45, 0.53, 0.94]}
        reset={true}
      >
        <section
          className="bg-gray-900 bg-no-repeat bg-cover bg-center"
          style={{
            backgroundImage: `url(${herobg})`,
          }}
        >

          <Navbar />
          <div className="flex flex-col justify-between max-w-xl px-4 mx-auto lg:pt-16 lg:flex-row md:px-8 lg:max-w-screen-xl">
            <div className="pt-16 mb-16 lg:mb-0 lg:pt-20 lg:max-w-lg lg:pr-5">
              <div className="max-w-xl mb-6">
                <div></div>
                <h2 className="max-w-lg mb-6 text-3xl font-bold text-amber-400 sm:text-6xl sm:leading-none">
                  The greatest
                  <br />
                  <span className="text-7xl md:text-8xl lg:text-9xl">
                    $ALPHA
                  </span>
                  <br />
                  of all time!
                </h2>
                <p className="text-base text-white md:text-lg">
                  Promoting the memecoin culture on Blast.
                </p>
              </div>
              <div className="flex items-center">
                <a
                  href="/#claim"
                  className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                >
                  Claim $ALPHA Allocation
                </a>
              </div>
            </div>
            <img
              src={bam2}
              className="w-full mx-auto xl:mr-24 md:max-w-xl"
              alt="hero"
            />
          </div>
        </section>
      </MotionAnimate>

      {/* Slider */}
      <MotionAnimate animation="scrollFadeIn" scrollPositions={[0.1, 0.9]}>
        <section>
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <Slider {...settings}>
              <div>
                <img src={a1} alt="a1" />
              </div>
              <div>
                <img src={a2} alt="a2" />
              </div>
              <div>
                <img src={a3} alt="a3" />
              </div>
              <div>
                <img src={a4} alt="a4" />
              </div>
              <div>
                <img src={a5} alt="a5" />
              </div>
              <div>
                <img src={a6} alt="a6" />
              </div>
              <div>
                <img src={a7} alt="a7" />
              </div>
              <div>
                <img src={a8} alt="a8" />
              </div>
              <div>
                <img src={a9} alt="a9" />
              </div>
              <div>
                <img src={a10} alt="a10" />
              </div>
            </Slider>
          </div>
        </section>
      </MotionAnimate>

      {/* Claim */}
      <MotionAnimate animation="scrollFadeIn" scrollPositions={[0.1, 0.9]}>
        <section id="claim">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
              <h2 className="max-w-lg mb-5 text-3xl font-bold tracking-tight text-amber-400 sm:text-4xl sm:leading-none md:mb-6 group">
                <span className="inline-block mb-1 sm:mb-4">Claim</span>
                <div className="h-1 ml-auto duration-300 origin-left transform bg-amber-400 scale-x-30 group-hover:scale-x-100" />
              </h2>
            </div>
            {/*Invite*/}
            <div className="lg:m-8 w-full mx-auto my-4 p-4 rounded-2xl bg-gradient-to-r from-amber-400 to-amber-600 shadow-2xl shadow-yellow-500/50 border border-yellow-500">
              <div className=" p-4 space-y-6 text-center">
                <div>
                  <p className="text-[20px] md:text-[25px] font-bold">My BlastAlpha Allocations</p>
                  {claimed && (
                    <div className="w-[70%] mx-auto bg-black  mt-[20px] text-[20px] text-white p-4 space-y-2 rounded-2xl">
                      <p>Amount claimed</p>
                      <h4 className="text-3xl">
                        {new Intl.NumberFormat().format(proof?.amount || 0)} $Alpha
                      </h4>
                    </div>
                  )}
                </div>

                {address ? (
                  <>
                    {proof?.amount || loading ? (
                      <>
                        {loading || fetchingClaimed ? (
                          <div className="w-[70%] flex justify-center items-center mx-auto space-y-[20px]">
                            <svg
                              aria-hidden="true"
                              className="w-[30px] mt-4 h-[30px] mx-auto text-white animate-spin fill-black"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="w-[70%] mx-auto space-y-[20px]">
                            {/* <ProgressBar
                              completed={Number(Number((reservation?.rate || 0) > 100 ? 100 : (reservation?.rate || 0))?.toFixed(2)?.replace(".00", ''))}
                              bgColor='#ffffff'
                              labelColor='#000000'
                              border
                              baseBgColor='#00000050'
                            /> */}

                            {claimed ? (
                              <h3 className="text-4xl !mt-[20px]">Token already claimed</h3>
                            ) : (
                              <div>
                                <div className="bg-black text-[20px] text-white p-4 space-y-2 rounded-2xl">
                                  <p>Claimable Amount</p>
                                  <h4 className="text-3xl">{new Intl.NumberFormat().format(proof?.amount || 0)}</h4>
                                </div>
                                <Web3Button
                                  contractAddress={tokenContract}
                                  contractAbi={ABI}
                                  style={{
                                    height: "48px",
                                    border: "none",
                                    width: "100%",
                                    margin: "0px",
                                    marginTop: "15px",
                                    paddingLeft: "40px",
                                    letterSpacing: "0.025em",
                                    paddingRight: "40px",
                                    color: "#ffffff",
                                    fontSize: "0.875rem",
                                    fontWeight: 500,
                                    background: "#000000",
                                    borderRadius: 10
                                  }}
                                  theme="light"
                                  isDisabled={proof?.amount <= 0}
                                  isLoading={fetchingClaimed}
                                  action={async (contract) => {
                                    await contract.call("claim", [ proof?.proof, proof?.amount ])
                                  }}
                                  onError={(err) => {
                                    alert(err.reason?.toUpperCase() || "Uh oh! Failed to claim tokens.");
                                  }}
                                  onSuccess={() => {
                                    refetchClaimed();
                                  }}
                                >
                                  Claim Tokens
                                </Web3Button>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="mt-[30px] w-[70%] mx-auto space-y-[15px]">
                        <div className="bg-black text-[20px] text-white p-4 space-y-2 rounded-2xl">
                          <p>Claimable Amount</p>
                          <h4 className="text-3xl">0.00 $Alpha</h4>
                        </div>
                        <h3 className="text-[22px] mt-[10px]">Address isn't on allowed list, try another address.</h3>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="mt-[30px] w-[70%] mx-auto space-y-[15px]">
                    <div className="bg-black text-[20px] text-white p-4 space-y-2 rounded-2xl">
                      <p>Link Your Wallet</p>
                      <h4 className="text-3xl">0.00</h4>
                    </div>
                    <ConnectWallet
                      style={{ height: "48px", width: "100%", paddingLeft: "35px", letterSpacing: "0.025em", paddingRight: "35px", color: "#ffffff", fontSize: "16px", fontWeight: 500, background: "#000000", borderRadius: 10 }}
                      theme="dark"
                      networkSelector="open"
                      hideTestnetFaucet={true}
                      switchToActiveChain={true}
                      modalSize="wide"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </MotionAnimate>

      {/* Tokenomics */}
      <MotionAnimate animation="scrollFadeIn" scrollPositions={[0.1, 0.9]}>
        <section id="tokenomics">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
              <h2 className="max-w-lg mb-5 text-3xl font-bold tracking-tight text-amber-400 sm:text-4xl sm:leading-none md:mb-6 group">
                <span className="inline-block mb-1 sm:mb-4">Tokenomics</span>
                <div className="h-1 ml-auto duration-300 origin-left transform bg-amber-400 scale-x-30 group-hover:scale-x-100" />
              </h2>
            </div>
            <div className="grid max-w-screen-lg gap-8 row-gap-6 sm:mx-auto grid-cols-2 md:grid-cols-3">
              <img src={tokenomics40} alt="tokenomics40" />
              <img src={tokenomics30} alt="tokenomics30" />
              <img src={tokenomics15} alt="tokenomics15" />
              <img src={tokenomics10} alt="tokenomics10" />
              <img src={tokenomics5} alt="tokenomics5" />
            </div>
          </div>
        </section>
      </MotionAnimate>
    </div>
  );
};

export default Home;