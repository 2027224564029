import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BlastBlastmainnet } from "@thirdweb-dev/chains";
import { ThirdwebProvider } from "@thirdweb-dev/react";

const THIRDWEB_CLIENT_ID = "fa34a1ac0daf305170d4f6ab3dfb88c8";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThirdwebProvider
      clientId={THIRDWEB_CLIENT_ID}
      activeChain={BlastBlastmainnet}
      supportedChains={[BlastBlastmainnet]}
      autoConnect={true}
      dAppMeta={{
        name: "Alpha",
        description: "The most memeable $ALPHA of all time promoting the meme culture on #Blast_L2. Built by BlastAlpha",
        logoUrl: "https://alphaonblast.meme/static/media/logo.afce72b4066e129650da.png",
        url: "https://alphaonblast.meme",
        isDarkMode: true,
      }}
    >
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
);

reportWebVitals();
