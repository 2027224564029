import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navbar } from "../../Components";

const nft = () => {
  const copyToClipboard = (e) => {
    e.preventDefault();
    const copyText = document.getElementById("copyText");
    copyText.select();
    document.execCommand("copy");
    toast.success("Invite link copied to clipboard!");
  };

  return (
    <div>
      <Toaster />

      <section>
      <Navbar />
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
            <h2 className="max-w-lg mb-5 text-3xl font-bold tracking-tight text-amber-400 sm:text-4xl sm:leading-none md:mb-6 group">
              <span className="inline-block mb-1 sm:mb-4">
                NFT Holders
              </span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-amber-400 scale-x-30 group-hover:scale-x-100" />
            </h2>
          </div>

          {/*Invite*/}
          <div className="w-full lg:m-8 my-4 p-4 rounded-2xl bg-gradient-to-r from-amber-400 to-amber-600 shadow-2xl shadow-yellow-500/50 border border-yellow-500">
            <div className=" p-4 space-y-4 text-center">
              <p>Blast Alpha Credits</p>
              <h3 className="text-4xl">300 Points</h3>

              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 text-white">
                <div className="bg-black p-4 space-y-4 rounded-2xl">
                  <p>Total NFTs</p>
                  <h4 className="text-3xl">30</h4>
                </div>
                <div className="bg-black p-4 space-y-4 rounded-2xl">
                  <p>NFT Points Earned</p>
                  <h4 className="text-3xl">300 </h4>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 text-white">
                <div className="bg-black p-4 space-y-4 rounded-2xl">
                  <p>Referred Friend</p>
                  <h4 className="text-3xl">30</h4>
                </div>
                <div className="bg-black p-4 space-y-4 rounded-2xl">
                  <p>Referral Points Earned</p>
                  <h4 className="text-3xl">300 </h4>
                </div>
              </div>

              <div className="bg-black p-4 space-y-4 rounded-2xl text-center text-white">
                <p>
                  Earn 250 more points for you and your friend when you refer
                </p>
                <form
                  className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16"
                  onSubmit={copyToClipboard}
                >
                  <input
                    type="text"
                    value={`https://blastalpha.com?invite=s`}
                    id="copyText"
                    readOnly
                    required
                    className="flex-grow w-max h-12 px-4 mb-3 text-neutral-900 transition duration-500 border-2 border-transparent rounded appearance-none md:mr-2 md:mb-0 focus:border-amber-500 focus:outline-none focus:shadow-outline"
                  />
                  <a
                    href="#0"
                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                  >
                    <button onclick={copyToClipboard}>Claim</button>
                  </a>
                </form>
                <p className="max-w-md mx-auto mb-10 text-xs text-white sm:text-sm md:mb-16">
                  Click the 'Copy' button to copy your invite link
                </p>
              </div>
            </div>
          </div>

          {/*Social Task*/}
          <div className=" w-full lg:m-8 p-4 rounded-2xl bg-gradient-to-r from-amber-400 to-amber-600 shadow-2xl shadow-yellow-500/50 border border-yellow-500">
            <h3 className="text-3xl">Social Tasks</h3>
            <div className=" p-4 space-y-4">
              <div className="grid lg:grid-cols-3  gap-4 ">
                <div className="bg-black p-4 space-y-4 rounded-2xl text-white">
                  <p>Follow Blast Alpha Twitter</p>
                  <p>200 Credits</p>
                  <a
                    href="#0"
                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                  >
                    Twitter
                  </a>
                </div>
                <div className="bg-black p-4 space-y-4 rounded-2xl text-white">
                  <p>Follow Blast Alpha Twitter</p>
                  <p>200 Credits</p>
                  <a
                    href="#0"
                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                  >
                    Twitter
                  </a>
                </div>
                <div className="bg-black p-4 space-y-4 rounded-2xl text-white">
                  <p>Follow Blast Alpha Twitter</p>
                  <p>200 Credits</p>
                  <a
                    href="#0"
                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                  >
                    Twitter
                  </a>
                </div>
                <div className="bg-black p-4 space-y-4 rounded-2xl text-white">
                  <p>Follow Blast Alpha Twitter</p>
                  <p>200 Credits</p>
                  <a
                    href="#0"
                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                  >
                    Twitter
                  </a>
                </div>
                <div className="bg-black p-4 space-y-4 rounded-2xl text-white">
                  <p>Follow Blast Alpha Twitter</p>
                  <p>200 Credits</p>
                  <a
                    href="#0"
                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                  >
                    Twitter
                  </a>
                </div>
                <div className="bg-black p-4 space-y-4 rounded-2xl text-white">
                  <p>Follow Blast Alpha Twitter</p>
                  <p>200 Credits</p>
                  <a
                    href="#0"
                    className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-amber-400 hover:bg-amber-500 focus:shadow-outline focus:outline-none"
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default nft;
